<template>
  <VCard class="popup" color="white">
    <VCardTitle class="title bold">
      Удалить "{{ title }}"?
    </VCardTitle>
    <VCardText>
      Данное действие необратимо и приведёт к полному
      удалению. Вы уверены?
    </VCardText>
    <VCardActions>
      <VRow class="justify-center" no-gutters>
        <VCol class="py-0 d-flex justify-center" cols="12">
          <VCol cols="6">
            <UniversalButton
              id="cancel"
              class="btn-width black--text elevation-0"
              color="#E0E0E0"
              icon=""
              text="Нет"
              x-large
              @click="confirmation('cancel')"
            />
          </VCol>
          <VCol cols="6">
            <UniversalButton
              id="delete"
              class="btn-width elevation-0"
              color="#E43539"
              icon=""
              text="Да, удалить"
              x-large
              @click="confirmation('delete')"
            />
          </VCol>
        </VCol>
      </VRow>
    </VCardActions>
  </VCard>
</template>

<script>
import UniversalButton from '@/components/UniversalButton.vue';

export default {
  components: {
    UniversalButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  emits: ['confirm'],
  mounted() {
    document.addEventListener('keyup', this.confirmationKeyboardListener);
  },
  methods: {
    confirmation(choice) {
      this.$emit('confirm', choice);
      document.removeEventListener('keyup', this.confirmationKeyboardListener);
    },
    confirmationKeyboardListener(e) {
      if (e.keyCode === 13) {
        this.confirmation('delete');
      } else if (e.keyCode === 27) {
        this.confirmation('cancel');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/page.scss';
@import '@/assets/scss/mixin.scss';

.popup{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 30vh;
  margin-left: auto;
  margin-right: auto;
  width: 375px;
  padding:1em;
  z-index: 100;
  background-color: white;
  border-radius: 10px !important;
  box-shadow: 0 1px 10px 0 gray;
  &>*{
    z-index: 2;
  }
  &::before{
    position: fixed;
    content:'';
    top:0;
    left:0;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100vh;
    z-index: -1;
  }
  &::after{
    position: absolute;
    content:'';
    top:0;
    left:0;
    background-color: white;
    border-radius: 10px !important;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.title{
  font-size: 25px;
  font-weight: 600 !important;
  color:black;
}

.btn-width{
  width: 100%;
  max-width: 100%;
  text-transform: uppercase;
}
</style>
