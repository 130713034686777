<template>
  <div>
    <div
      v-show="!canAddSlide"
      class="subtitle-2 font-weight-bold mb-4"
    >
      *** Перед загрузкой картинок сохраните Story.
    </div>
    <div
      class="mb-2"
      :class="{ 'grey--text': !canAddSlide }"
    >
      <div class="section-title">
        Картинки
      </div>
      <div class="caption">
        Размер изображения - 1080х1920рх, вертикальное. Вес не более 200 КБ.
      </div>
    </div>
    <div
      v-for="(slide, index) in sortedSlides"
      :id="slide.id"
      :key="index"
      class="grab"
      draggable
      @dragend="dragEnd"
      @dragover.prevent
      @dragover.stop
      @dragstart="dragStart(index, $event)"
      @drop.prevent="dragDrop(index, $event)"
    >
      <Slide
        :position="index + 1"
        :slide="slide"
        :story-id="storyId"
        @delete-slide="openDeletionPopUp(slide)"
        @open-image="openImage(index + (storyCover ? 1 : 0))"
      />
    </div>
    <VBtn
      class="subtitle-2 font-weight-bold mb-4"
      color="#E43539"
      :disabled="!canAddSlide"
      text
      @click="addSlide"
    >
      <VIcon
        class="mr-2"
        v-text="'mdi-plus'"
      />
      Добавить картинку
    </VBtn>
    <DeletionPopUp
      v-show="deletionDialog"
      :title="slideToDelete.title || ''"
      @confirm="deleteSlide"
    />
    <VDialog
      v-model="imageDialog"
      content-class="elevation-0"
      width="552"
    >
      <ImageWindow
        :key="key"
        :images="images"
        :selected="selectedImage"
        @close="imageDialog = false"
      />
    </VDialog>
  </div>
</template>

<script>
import {
  fetchSlides, createSlide, updateSlide, deleteSlide,
} from '@/api/api';
import Slide from '@/components/Slide.vue';
import DeletionPopUp from '@/components/DeletionPopUp.vue';
import ImageWindow from '@/components/reusable/ImageWindow.vue';

export default {
  components: {
    Slide, DeletionPopUp, ImageWindow,
  },
  props: {
    storyId: {
      type: [String, Number],
      default: '',
    },
    storyCover: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      slides: [],
      defaultSlides: [],
      slideToDelete: {},
      slidesToDeleteList: [],
      deletionDialog: false,
      imageDialog: false,
      selectedImage: '',
      key: 0,
      dragging: {
        startPosition: 1,
        element: Object,
      },
    };
  },
  async mounted() {
    if (this.storyId) {
      await this.getSlides();
    }
  },
  computed: {
    sortedSlides() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.slides.sort(
        // eslint-disable-next-line no-nested-ternary
        (a, b) => (a.position > b.position ? 1 : a.position < b.position ? -1 : 0),
      );
    },
    canAddSlide() {
      return !!this.storyId;
    },
    images() {
      const images = [];
      if (this.storyCover) {
        images.push({ url: this.storyCover });
      }
      this.slides.forEach((slide) => {
        if (slide.image) {
          images.push({
            url: slide.image,
            buttonText: slide.buttonName,
            buttonColor: slide.buttonColor,
            textColor: slide.textColor,
          });
        }
      });
      return images;
    },
  },
  methods: {
    getSlides() {
      fetchSlides(this.storyId).then((response) => {
        this.slides = response;
        this.defaultSlides = [...JSON.parse(JSON.stringify(this.slides))];
      });
    },
    addSlide() {
      const slide = {
        image: '',
        position: this.slides.length + 1,
        buttonName: '',
        buttonType: 'inside_link',
        buttonLink: '',
        buttonColor: '#E43539',
        textColor: '#FFFFFF',
      };
      this.slides.push(slide);
    },
    openDeletionPopUp(slide) {
      this.slideToDelete = slide;
      this.slideToDelete.title = `Картинку ${slide.position}`;
      this.deletionDialog = true;
    },
    deleteSlide(userChoice) {
      const events = {
        delete: () => {
          if (this.slideToDelete.storyId) {
            this.slidesToDeleteList.push(this.slideToDelete);
          }
          const index = this.slides.indexOf(this.slideToDelete);
          this.slides.splice(index, 1);
          this.deletionDialog = false;
        },
        cancel: () => {
          this.deletionDialog = false;
        },
      };
      events[userChoice]();
      this.slideToDelete = {};
    },
    async saveSlidesList() {
      await this.slides.forEach((slide) => {
        this.saveSlide(slide);
      });
      await this.slidesToDeleteList.forEach((slide) => {
        deleteSlide(slide);
      });
    },
    async saveSlide(slide) {
      if (slide.id && this.hasChanged(slide)) {
        await updateSlide(slide);
      } else if (!slide.id) {
        await createSlide(slide, this.storyId);
      }
    },
    hasChanged(slide) {
      const defaultSlide = this.defaultSlides.find((findSlide) => slide.id === findSlide.id);
      return slide.image !== defaultSlide.image
        || slide.position !== defaultSlide.position
        || slide.buttonName !== defaultSlide.buttonName
        || slide.buttonType !== defaultSlide.buttonType
        || slide.buttonLink !== defaultSlide.buttonLink
        || slide.buttonColor !== defaultSlide.buttonColor
        || slide.textColor !== defaultSlide.textColor;
    },
    updateSlidesPositionIncreased(slides, newPosition, oldPosition) {
      slides.map((item, index) => index)
        .filter((index) => index > oldPosition && index <= newPosition)
        // eslint-disable-next-line no-return-assign
        .forEach((index) => this.slides[index].position = (index - 1));
    },
    updateSlidesPositionDecreased(slides, newPosition, oldPosition) {
      slides.map((item, index) => index)
        .filter((index) => index >= newPosition && index < oldPosition)
        // eslint-disable-next-line no-return-assign
        .forEach((index) => this.slides[index].position = (index + 1));
    },
    dragStart(index, event) {
      this.dragging.startPosition = index;
      event.target.classList.add('active-drag');
      this.dragging.element = event.target;
    },
    dragDrop(dropPosition) {
      if (dropPosition !== this.dragging.startPosition) {
        const dragPosition = this.dragging.startPosition;
        this.slides[dragPosition].position = dropPosition;
        if (dropPosition > dragPosition) {
          this.updateSlidesPositionIncreased(this.slides, dropPosition, dragPosition);
        } else {
          this.updateSlidesPositionDecreased(this.slides, dropPosition, dragPosition);
        }
      }
    },
    dragEnd() {
      this.dragging.element.classList.remove('active-drag');
    },
    openImage(image) {
      this.selectedImage = image;
      this.key += 1;
      this.imageDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
