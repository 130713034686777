<template>
  <VMenu
    v-model="dateMenu"
    :attach="attach || false"
    :close-on-content-click="false"
    min-width="auto"
    nudge-bottom="5"
    nudge-left="7"
    offset-y
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <VInput
        v-model="dateText"
        v-bind="attrs"
        class="d-flex br-4 pointer pa-1"
        :class="{'grey lighten-5':!!dateText}"
        hide-details="auto"
        prepend-icon="mdi-calendar-blank-outline"
        :rules="rules"
        v-on="on"
      >
        <span class="date-text mt-1"> {{ dateText ? dateText : 'Укажите дату' }} </span>
      </VInput>
    </template>
    <VCard class="d-flex flex-column py-4" width="296">
      <div class="px-4" :class="{'d-flex': !hasTimeSettings}">
        <div
          v-for="index in (hasDateEnd ? date.length : 1)"
          v-if="date"
          :key="index"
          class="d-flex pb-2 full-width"
          :class="hasTimeSettings ? 'justify-space-between' : 'justify-center'"
        >
          <VBtn
            class="elevation-0 d-flex justify-space-between px-2"
            :class="index === 1 ? 'greyish' : 'red-box'"
            height="34"
            hide-details
            outlined
            :width="hasTimeSettings ? '67%': '97%'"
          >
            {{ formatDateDot(hasDateEnd ? date[index - 1] : date) }}
            <VIcon
              v-if="hasDateEnd && index === 1"
              small
              @click="clearDate"
            >
              mdi-close
            </VIcon>
          </VBtn>
          <div
            v-if="hasTimeSettings"
            class="d-flex align-center justify-center greyish time-field"
          >
            <input
              v-model="hoursRange[index - 1]"
              class="time-box subtitle-2 text-center"
              @input="evaluate({key: 'hoursRange', index: index-1, max: 23})"
              @keypress="parseNumber"
            >
            :
            <input
              v-model="minutesRange[index - 1]"
              class="time-box subtitle-2 text-center"
              @input="evaluate({key: 'minutesRange', index: index-1, max: 59})"
              @keypress="parseNumber"
            />
          </div>
        </div>
      </div>
      <VDatePicker
        v-model="date"
        class="story-date elevation-0"
        :close-on-content-click="false"
        color="#E43539"
        :first-day-of-week="1"
        :header-date-format="formatDateMonthAndYear"
        locale="ru-KZ"
        :min="getMin"
        no-title
        :range="hasDateEnd"
        :show-current="false"
        :weekday-format="getDay"
        @dblclick="clearDate"
      />
      <VDivider class="mx-4 mb-2" />
      <div v-if="!range" class="d-flex justify-space-between align-center pl-5 pr-4 pt-4">
        <div> Есть дата окончания </div>
        <VSwitch
          v-model="hasDateEnd"
          class="my-0"
          color="#E43539"
          hide-details
          :ripple="false"
          @change="updateHasDateEnd"
        />
      </div>
      <div
        v-if="hasTimePicker"
        class="d-flex justify-space-between align-center pl-5 pr-4 pt-4"
      >
        <div> Есть время </div>
        <VSwitch
          v-model="hasTimeSettings"
          class="my-0"
          color="#E43539"
          hide-details
          :ripple="false"
        />
      </div>
    </VCard>
  </VMenu>
</template>

<script>
import { formatDateMonthAndYear, formatDateWithTime, twoDigitFormatting } from '@/helpers/date';
import { parseNumber } from '@/helpers/price';

export default {
  props: {
    dateStart: {
      type: String,
      default: null,
    },
    dateEnd: {
      type: String,
      default: null,
    },
    range: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: String,
    },
    hasTimePicker: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['date-start', 'date-end'],
  data() {
    return {
      dateMenu: false,
      date: '',
      hoursRange: ['00', '23'],
      minutesRange: ['00', '59'],
      hasTimeSettings: false,
      hasDateEnd: false,
      formatTime: twoDigitFormatting,
      formatDateMonthAndYear,
      formatDateWithTime,
      parseNumber,
      rules: [
        (value) => !!value || 'Обязательное поле',
        () => !!(this.range ? !!this.date[1] : true) || 'Введите дату окончания',
        () => !!(this.hoursRange[0] && this.hoursRange[1]) || 'Введите часы',
        () => !!(this.minutesRange[0] && this.minutesRange[1]) || 'Введите минуты',
      ],
    };
  },
  mounted() {
    if (this.dateStart) {
      this.hasTimeSettings = this.hasTimePicker;
      const dateStart = new Date(this.dateStart);
      this.hoursRange[0] = this.formatTime(dateStart.getHours());
      this.minutesRange[0] = this.formatTime(dateStart.getMinutes());
      if (this.dateEnd) {
        this.hasDateEnd = true;
        const dateEnd = new Date(this.dateEnd);
        this.date = [];
        this.date[0] = this.formatDateDash(dateStart);
        this.date[1] = this.formatDateDash(dateEnd);
        this.hoursRange[1] = this.formatTime(dateEnd.getHours());
        this.minutesRange[1] = this.formatTime(dateEnd.getMinutes());
      } else {
        this.date = this.formatDateDash(dateStart);
      }
    } else {
      this.hasDateEnd = this.range;
      this.updateHasDateEnd();
    }
  },
  computed: {
    dateText() {
      if (this.hasDateEnd) {
        return this.date.map((date, index) => this.formatDateText(date, this.hoursRange[index], this.minutesRange[index])).join(' → ');
      }
      return this.formatDateText(this.date, this.hoursRange[0], this.minutesRange[0]);
    },
    getMin() {
      const now = new Date();
      if (this.hasDateEnd && this.date.length) {
        const dateStart = new Date(this.date[0]);
        return dateStart.getTime() < now.getTime()
          ? now.toISOString()
          : dateStart.toISOString();
      }
      return now.toISOString();
    },
    dateStartToEdit() {
      const date = this.date.length === 2 ? this.date[0] : this.date;
      const time = this.hasTimePicker ? ` ${this.hoursRange[0]}:${this.minutesRange[0]}:00` : '';
      return date + time;
    },
    dateEndToEdit() {
      const time = this.hasTimePicker ? ` ${this.hoursRange[1]}:${this.minutesRange[1]}:00` : '';
      return this.date.length === 2 ? (this.date[1] + time) : null;
    },
  },
  methods: {
    formatDateText(date, hours, minutes) {
      if (this.hasTimeSettings) {
        return `${this.formatDateDot(date)} • ${hours || '??'}:${minutes || '??'}`;
      }
      return this.formatDateDot(date);
    },
    formatDateDot(date) {
      return date ? new Date(date).toLocaleDateString('ru-KZ') : '';
    },
    formatDateDash(date) {
      return this.formatDateDot(date).split('.').reverse().join('-');
    },
    clearDate() {
      this.date = this.hasDateEnd ? [] : '';
      this.hoursRange = ['00', '23'];
      this.minutesRange = ['00', '59'];
    },
    getDay(date) {
      const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
      const i = new Date(date).getDay();
      return daysOfWeek[i];
    },
    evaluate(settings) {
      let value = this[settings.key][settings.index];
      if (value.length > 2) {
        value = value.slice(0, 2);
      }
      if (value > settings.max) {
        value = settings.max;
      }
      this[settings.key][settings.index] = value;
    },
    convertToArray(value) {
      if (value.length) {
        return Array.isArray(value) ? value : [value];
      }
      return [];
    },
    convertToString(value) {
      if (value.length) {
        return Array.isArray(value) ? value[0] : value;
      }
      return '';
    },
    updateHasDateEnd() {
      this.date = this.hasDateEnd
        ? this.convertToArray(this.date)
        : this.convertToString(this.date);
    },
  },
  watch: {
    hasTimeSettings(val) {
      if (!val) {
        this.hoursRange = ['00', '23'];
        this.minutesRange = ['00', '59'];
      }
    },
    dateStartToEdit(val) {
      this.$emit('date-start', val);
    },
    dateEndToEdit(val) {
      this.$emit('date-end', val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
