<template>
  <VBreadcrumbs class="greyish br-4" :items="items">
    <template v-slot:divider>
      <VIcon>mdi-chevron-right</VIcon>
    </template>
  </VBreadcrumbs>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-breadcrumbs__item {
    color: #E43539;
    font-size: 12px;
  }
  .v-breadcrumbs__divider {
    padding: 0 !important;
  }
  .v-breadcrumbs__item--disabled {
    color: black !important;
  }
}
.greyish {
  padding: 3px 8px;
  margin: 6px 16px;
}
</style>
