<template>
  <div class="d-flex align-center mt-2">
    <VTextField
      class="mr-4"
      :clearable="!disabled"
      color="success"
      hide-details
      placeholder="Загрузите список авторизованных пользователей с компьютера..."
      readonly
      :value="fileName"
      @click:clear="clearFile"
    />
    <VBtn
      class="upload-button"
      color="#048848"
      :disabled="disabled"
      elevation="0"
      fab
      small
    >
      <VIcon
        color="white"
        size="26"
        v-text="'mdi-cloud-upload'"
      />
      <VTooltip
        v-model="showTooltip"
        color="error"
        min-width="230"
        nudge-right="115"
        nudge-top="10"
        top
      >
        <template v-slot:activator="{ attrs }">
          <input
            ref="file"
            v-bind="attrs"
            accept=".xlsx, .xls"
            type="file"
            @change="fileSelected"
          >
        </template>
        <span>
          Размер файла больше 512 Кб
        </span>
      </VTooltip>
    </VBtn>
  </div>
</template>

<script>

export default {
  emits: ['file-uploaded'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileName: '',
      showTooltip: false,
    };
  },
  methods: {
    fileSelected(event) {
      this.showTooltip = false;
      const file = event.target.files[0];
      if (file && file.size > 512 * 1024) {
        this.activateTooltip();
      } else {
        this.fileName = file.name;
        this.$emit('file-uploaded', file);
      }
    },
    clearFile() {
      this.fileName = '';
      this.$emit('file-uploaded', '');
    },
    activateTooltip() {
      this.showTooltip = true;
      setTimeout(() => {
        this.showTooltip = false;
      }, 4000);
    },
  },
  watch: {
    disabled() {
      if (this.disabled) {
        this.fileName = 'Файл уже загружен';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/mixin.scss";
@import "src/assets/scss/main.scss";

@keyframes appearance {
  from { transform: scale(.5) rotate(-15deg); }
  50%{ transform: scale(1.05) rotate(15deg); }
  to { transform: scale(1) rotate(0deg); }
}
.upload-button {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.upload-button input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}
::v-deep {
  .mdi-close {
    font-size: 18px;
    color: #A4A2B7;
  }
}
</style>
