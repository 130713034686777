<template>
  <div class="border-1 br-8 mb-2">
    <div class="pt-3 pl-3">
      {{ label }}
    </div>
    <VTextField
      id="wide-text-field"
      v-model="valueToEdit"
      class="font-weight-bold pb-2 wide-title"
      color="#E43539"
      flat
      hide-details="auto"
      :placeholder="placeholder"
      :rules="rules"
      solo
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Название',
    },
    placeholder: {
      type: String,
      default: 'Введите название',
    },
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [
        (value) => !!value || 'Обязательное поле',
      ],
    },
  },
  emits: ['input'],
  data() {
    return {
      valueToEdit: '',
    };
  },
  mounted() {
    if (this.value) {
      this.valueToEdit = this.value;
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.valueToEdit = val;
      }
    },
    valueToEdit(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
