<template>
  <div class="d-flex justify-center">
    <VSwitch
      id="full-screen-toggle"
      v-model="valueToEdit"
      class="my-4"
      color="#E43539"
      hide-details
      :ripple="false"
      @change="emitChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['click', 'input'],
  data() {
    return {
      valueToEdit: false,
    };
  },
  mounted() {
    this.valueToEdit = this.value;
  },
  methods: {
    emitChange() {
      const actions = {
        true: 'add-full-screen',
        false: 'remove-full-screen',
      };
      this.$emit('click', actions[this.valueToEdit]);
      this.$emit('input', this.valueToEdit);
    },
  },
  watch: {
    value(val) {
      this.valueToEdit = val;
    },
  },
};
</script>
