var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('VSimpleTable',{staticClass:"stories-table px-8 overflow-y-auto",attrs:{"fixed-header":"","height":_vm.tableHeight}},[[_c('thead',[_c('tr',[_vm._l((_vm.headers),function(header,index){return _c('th',{key:index,style:(`width: ${header.width}`)},[_c('div',{staticClass:"d-flex align-center",class:`${header.class} justify-${header.align}`},[(header.description)?_c('div',[_c('VIcon',{staticClass:"info-icon mr-1 d-flex align-self-center pointer",attrs:{"color":"#4D4F67","size":"16"},domProps:{"textContent":_vm._s('mdi-alert-circle-outline')}}),_c('div',{staticClass:"description title-description mt-7"},[_vm._v(" "+_vm._s(header.description)+" ")])],1):_vm._e(),_c('div',[_vm._v(" "+_vm._s(header.text)+" ")]),(header.sortable)?_c('div',{staticStyle:{"position":"relative"}},[_c('VIcon',{staticClass:"sort-icon ml-1",class:{'rotate180deg': _vm.selectedSort[header.sortValue] === 'desc'},attrs:{"color":_vm.isActiveSort(header.sortValue) ? 'black' : 'grey',"dark":"","size":"18"},domProps:{"textContent":_vm._s('mdi-arrow-up')},on:{"click":function($event){$event.stopPropagation();return _vm.setSort(header.sortValue)}}}),_c('div',{staticClass:"description sort-description mt-6"},[_vm._v(" Сортировать ")])],1):_vm._e()])])}),_c('th')],2),_vm._t("filters")],2),_c('tbody',[_vm._l((_vm.itemsToEdit),function(item,itemIndex){return _c('tr',{key:itemIndex},_vm._l((_vm.headers),function(header,headerIndex){return _c('td',{key:headerIndex,class:`text-${header.align}`,style:(`max-width: ${header.width}`),on:{"click":function($event){header.clickable ? _vm.$emit('edit', item) : ''}}},[(header.component)?_c(_vm.getComponentName(header),_vm._b({tag:"Component",class:header.class,on:{"click":function($event){return _vm.handler($event, item)}}},'Component',_vm.getComponentProps(item, header),false)):_c('div',{class:{
                'text--secondary': (header.value === 'dateRangeText' && item.isExpired),
                'bright-green-text': item[header.value] === 'Активирован',
                'red--text text--darken-1': item[header.value] === 'Деактивирован',
                'clickable': header.clickable,
                [header.class]: true,
              }},[_vm._v(" "+_vm._s(_vm.getValue(item, header.value))+" ")])],1)}),0)}),(!(_vm.itemsToEdit && _vm.itemsToEdit.length))?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.headers.length}},[_vm._v(" Нет данных ")])]):_vm._e()],2)]],2),(_vm.showPages)?_c('VPagination',{staticClass:"py-4",attrs:{"length":_vm.totalPage,"total-visible":7},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_c('VCard',{staticClass:"bottom-sticky grey lighten-5",attrs:{"flat":"","height":"57"}},[_c('VDivider'),(!_vm.loading)?_c('div',{staticClass:"d-flex justify-center align-center footer py-2"},[_vm._v(" "+_vm._s(`${_vm.slidesRangePerPage} из ${_vm.totalCount}`)+" "),_c('VDivider',{staticClass:"ml-3",attrs:{"inset":"","vertical":""}}),_c('VBtn',{attrs:{"disabled":_vm.currentPage <= 1,"icon":"","small":""},on:{"click":function($event){_vm.currentPage -= 1}}},[_c('VIcon',{attrs:{"size":"20"},domProps:{"textContent":_vm._s('mdi-chevron-left')}})],1),_c('VBtn',{attrs:{"disabled":_vm.currentPage >= _vm.totalPage,"icon":"","small":""},on:{"click":function($event){_vm.currentPage += 1}}},[_c('VIcon',{attrs:{"size":"20"},domProps:{"textContent":_vm._s('mdi-chevron-right')}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }