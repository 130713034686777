<template>
  <div class="d-flex">
    <VIcon
      class="custom-loader mr-2"
      :color="color"
      size="20"
    >
      {{ icon }}
    </VIcon>
    <p class="blue-grey--text text--darken-3 my-auto">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-cached',
    },
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#5B32D3',
    },
  },
};
</script>

<style lang="scss" scoped>

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
</style>
