<template>
  <VBtn
    class="default-btn button__add elevation-0 pr-4"
    :color="color"
    :dark="dark"
    :disabled="disabled"
    :large="large"
    :outlined="outlined"
    :rounded="rounded"
    :small="small"
    :style="styles"
    :x-large="xLarge"
    :x-small="xSmall"
    @click="$emit('click')"
  >
    <VIcon v-if="icon" left>
      mdi-{{ icon }}
    </VIcon>
    {{ text }}
  </VBtn>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Сохранить',
    },
    color: {
      type: String,
      default: '#4CAF50',
    },
    icon: {
      type: String,
      default: 'plus',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/page.scss";

</style>
