<template>
  <div
    class="d-flex justify-center"
    flat
    style="position: relative"
  >
    <VBtn
      v-if="images.length > 1"
      class="mx-4 my-auto"
      height="44"
      width="44"
      @click="prev"
    >
      <VIcon>mdi-arrow-left</VIcon>
    </VBtn>
    <VCard
      class="br-16 overflow-hidden"
      :loading="show"
      width="400"
    >
      <VWindow v-model="model" continuous>
        <VWindowItem
          v-for="(image, index) in images"
          :key="index"
        >
          <VImg
            :aspect-ratio="1/1.8"
            :src="image.url"
          >
            <div class="d-flex flex-column justify-space-between full-height">
              <VItemGroup
                v-if="images.length > 1"
                v-model="model"
                class="d-flex justify-space-around pt-5 px-5"
                mandatory
              >
                <VItem
                  v-for="(i, imageIndex) in images"
                  :key="imageIndex"
                  v-slot="{ active, toggle }"
                >
                  <VBtn
                    :class="{ 'add-opacity': !active }"
                    elevation="0"
                    height="2"
                    :input-value="active"
                    :width="`${ 90/images.length }%`"
                    @click="toggle"
                  />
                </VItem>
              </VItemGroup>
              <VBtn
                v-if="image.buttonText"
                class="br-24 ma-5 font-weight-bold elevation-0 subtitle-1"
                :color="image.buttonColor"
                height="48"
                :style="`color: ${image.textColor}`"
              >
                {{ image.buttonText }}
              </VBtn>
            </div>
          </VImg>
        </VWindowItem>
      </VWindow>
    </VCard>
    <VBtn
      absolute
      height="44"
      right
      width="44"
      @click="close"
    >
      <VIcon>mdi-close</VIcon>
    </VBtn>
    <VBtn
      v-if="images.length > 1"
      class="mx-4 my-auto"
      height="44"
      width="44"
      @click="next"
    >
      <VIcon>mdi-arrow-right</VIcon>
    </VBtn>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: [Number, String],
      default: 0,
    },
    images: {
      type: Array,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      model: 0,
      show: false,
    };
  },
  mounted() {
    this.model = this.selected;
  },
  methods: {
    next() {
      this.model = this.model + 1 === this.images.length
        ? 0
        : this.model + 1;
    },
    prev() {
      this.model = this.model - 1 < 0
        ? this.images.length - 1
        : this.model - 1;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
