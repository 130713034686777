<template>
  <VCard
    class="color-picker px-4 pt-4 pb-2"
    width="272"
  >
    <div class="d-flex justify-space-between">
      <p>Цвет</p>
      <VBtn
        fab
        height="25"
        text
        width="25"
        @click="$emit('close')"
      >
        <VIcon
          color="#4D4F67"
          :size="20"
          v-text="'mdi-window-close'"
        />
      </VBtn>
    </div>
    <VColorPicker
      v-model="pickerColor"
      canvas-height="240"
      class="elevation-0 br-0"
      hide-inputs
      @input="updateColor"
    />
    <div class="d-flex mr-1">
      <StoryTextField
        class="mr-2"
        label="HEX"
        :value="valueToEdit"
        @input="updateColor"
      />
      <VBtn
        class="mt-4"
        :disabled="!(valueToEdit || valueToEdit.length)"
        fab
        height="25"
        text
        width="25"
        @click="$emit('saveColor', pickerColor)"
      >
        <VIcon color="#A4A2B7" v-text="'mdi-plus'" />
      </VBtn>
    </div>
    <div>
      <div class="font-weight-bold mb-2">
        Сохраненные цвета
      </div>
      <div class="d-flex flex-wrap">
        <VBtn
          v-for="(color, index) in savedColors"
          :key="index"
          class="elevation-0 mr-1 mb-2 grab"
          :class="{'grey-br': color === '#FFFFFF'}"
          :color="color"
          draggable
          fab
          height="20"
          width="20"
          @click="updateColor(color)"
          @dragend="handleDragEnd($event, index)"
          @dragstart="handleDragStart"
        />
      </div>
    </div>
  </VCard>
</template>

<script>
import StoryTextField from '@/components/reusable/StoryTextField.vue';

export default {
  components: { StoryTextField },
  props: {
    value: {
      type: String,
      required: true,
    },
    default: {
      type: String,
      default: '#E43539',
    },
    savedColors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close', 'saveColor', 'input', 'deleteColor'],
  data() {
    return {
      pickerColor: this.default,
      valueToEdit: '',
    };
  },
  mounted() {
    this.valueToEdit = this.value;
  },
  methods: {
    isHexType(color) {
      return color.startsWith('#') && color.length === 7;
    },
    updateColor(color) {
      if (this.isHexType(color)) {
        this.$emit('input', color);
      }
    },
    handleDragStart(event) {
      event.target.classList.add('dragging');
    },
    handleDragEnd(event, index) {
      const parent = document.querySelector('.color-picker');
      const dragElement = event.target;

      const parentRect = parent.getBoundingClientRect();
      const elementRect = dragElement.getBoundingClientRect();

      if (
        (event.clientX + 5) < parentRect.left
        || (elementRect.x + event.offsetX) > (parentRect.right + 5)
        || event.clientY < parentRect.top
        || (elementRect.y + event.offsetY - 11) > parentRect.bottom
      ) {
        this.$emit('deleteColor', index);
      }
      dragElement.classList.remove('dragging');
    },
  },
  watch: {
    value(val) {
      this.valueToEdit = val;
    },
    valueToEdit(val) {
      this.pickerColor = val && this.isHexType(val) ? val : this.default;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-color-picker__controls {
    padding: 16px 8px 16px 2px;
  }
}
</style>
