<template>
  <div class="mt-6">
    <div class="section-title mb-2">
      Персонализация Story
    </div>
    <VDivider />
    <VRow>
      <VCol
        cols="9"
        md="10"
        sm="12"
      >
        <div>
          <div class="mt-3 mb-2 grey-subtitle subtitle-1">Тип пользователей</div>
          <VSelect
            v-model="personalized.showForUnauthorized"
            append-icon="mdi-chevron-up"
            background-color="#FAFAFB"
            color="black"
            dense
            flat
            item-color="red"
            item-text="title"
            item-value="value"
            :items="userTypes"
            solo
          />
        </div>
        <div class="mt-1">
          <div class="subtitle-1 font-weight-bold">
            Показ авторизованным пользователям согласно количеству успешных заказов
          </div>
          <div class="d-flex align-center mt-3 success-orders">
            <VTextField
              v-model="personalized.minOrdersCount"
              v-mask="'##########'"
              background-color="#F4F6FF"
              flat
              hide-details
              placeholder="0"
              solo
            />
            <VDivider class="mx-2"/>
            <VTextField
              v-model="personalized.maxOrdersCount"
              v-mask="'##########'"
              background-color="#F4F6FF"
              flat
              hide-details
              placeholder="∞"
              solo
            />
          </div>
          <div class="caption mt-2">
            Вы можете выбрать от 0 до 0, чтобы выбрать аудиторию без успешных заказов
          </div>
        </div>
        <ExcelFileUploader
          :disabled="personalized.hasUsers"
          @file-uploaded="handleFileUpload"
        />
        <div class="caption mt-3">
          Вы можете загрузить файл в формате .xls, .xlsx. Excel-файл должен весить
          максимум 512 Кб, примерно 55 тыс. строк с ID номерами пользователей. Загрузить
          можно только 1 файл.
        </div>
      </VCol>
    </VRow>
  </div>
</template>

<script>
import { fetchStoryPersonalized, updateStoryPersonalized } from '@/api/api';
import ExcelFileUploader from '@/components/reusable/ExcelFileUploader.vue';

export default {
  components: {
    ExcelFileUploader,
  },
  props: {
    storyId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      personalized: {
        showForUnauthorized: true,
        maxOrdersCount: null,
        minOrdersCount: null,
        hasUsers: false,
        usersExcel: '',
      },
      userTypes: [
        { title: 'Для всех пользователей (по умолчанию)', value: true },
        { title: 'Только для авторизованных пользователей', value: false },
      ],
    };
  },
  mounted() {
    fetchStoryPersonalized(this.storyId).then((response) => {
      this.personalized = response;
    });
  },
  methods: {
    handleFileUpload(file) {
      this.personalized.usersExcel = file;
    },
    updatePersonalized() {
      const formData = new FormData();
      if (this.personalized.maxOrdersCount) {
        formData.append('completed_orders_max', this.personalized.maxOrdersCount);
      }
      if (this.personalized.minOrdersCount) {
        formData.append('completed_orders_min', this.personalized.minOrdersCount);
      }
      formData.append('show_for_unauthorized', this.personalized.showForUnauthorized);
      formData.append('users_excel', this.personalized.usersExcel);
      updateStoryPersonalized(this.storyId, formData);
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/main.scss";

::v-deep {
  .success-orders {
    .v-text-field {
      max-width: 84px;
    }
    .v-input__control {
      min-height: 32px;
    }
    input {
      text-align: center !important;
    }
    .v-divider {
      max-width: 14px;
      border-width: 2px 0 0 0;
    }
  }
  .v-file-input .v-file-input__text--placeholder {
    color: #4D4F67;
  }
  .mdi-chevron-up {
    font-size: 18px;
  }
  .v-list-item {
    margin: 0 6px;
    border-radius: 4px;
    overflow: hidden;
    min-height: 30px;
    &:hover {
      background-color: #FFEEEE;
    }
    &--link:before {
      background-color: transparent;
    }
  }
}
</style>
