<template>
  <VInput
    v-model="imageToEdit"
    :rules="required ? rules : []"
  >
    <VCard
      class="d-flex justify-center greyish br-8"
      flat
      :height="height"
      :min-width="width"
      :width="width"
    >
      <img
        v-if="imageToEdit"
        alt="image"
        class="br-8 full-width full-height"
        :class="isValidImg ? 'cover' : 'pa-5'"
        draggable="false"
        :src="imgUrl"
        @error="isValidImg = false"
      >
      <div
        v-if="!imageToEdit"
        class="d-flex flex-column justify-center align-center"
      >
        <UploadButton
          color="transparent"
          image-type="food"
          :max-file-size="1000"
          show-progress
          slot-icon
          :text="text"
          @image-uploaded="imageToEdit = $event"
        >
          <template v-slot:icon>
            <VImg
              contain
              max-width="35"
              :src="require('@/assets/icons/upload.svg')"
            />
          </template>
        </UploadButton>
      </div>
      <VCard
        class="absolute pa-1 d-flex"
        :class="enlargeable && imageToEdit && isValidImg ? 'justify-space-between' : 'justify-end'"
        color="transparent"
        flat
        :width="width"
      >
        <VBtn
          v-if="enlargeable && imageToEdit && isValidImg"
          color="rgba(0, 0, 0, 0.4)"
          fab
          height="24"
          width="24"
          @click="$emit('open-image')"
        >
          <VIcon color="white" small>
            mdi-crop-free
          </VIcon>
        </VBtn>
        <VBtn
          v-show="deletable"
          color="rgba(0, 0, 0, 0.4)"
          fab
          height="24"
          width="24"
          @click="$emit('delete')"
        >
          <VIcon color="white" small>
            mdi-close
          </VIcon>
        </VBtn>
      </VCard>
    </VCard>
  </VInput>
</template>

<script>
import imgUploadFailureIcon from '@/assets/icons/img_upload_failure.svg';
import UploadButton from '@/components/UploadButton.vue';

export default {
  components: { UploadButton },
  props: {
    value: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: 'Загрузить картинку',
    },
    width: {
      type: [String, Number],
      default: 110,
    },
    height: {
      type: [String, Number],
      default: 140,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    enlargeable: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['open-image', 'delete', 'input'],
  data() {
    return {
      isValidImg: true,
      imageToEdit: '',
      rules: [
        (value) => !!value || 'Загрузите картинку',
      ],
    };
  },
  mounted() {
    if (this.value) {
      this.imageToEdit = this.value;
    }
  },
  computed: {
    imgUrl() {
      return this.isValidImg ? this.imageToEdit : imgUploadFailureIcon;
    },
  },
  watch: {
    value(image) {
      this.imageToEdit = image;
    },
    imageToEdit(val) {
      this.isValidImg = true;
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

::v-deep {
  .v-messages__message {
    text-align: center;
  }
}

</style>
