<template>
  <div>
    <VSimpleTable
      class="stories-table px-8 overflow-y-auto"
      fixed-header
      :height="tableHeight"
    >
      <template>
        <thead>
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="index"
              :style="`width: ${header.width}`"
            >
              <div
                class="d-flex align-center"
                :class="`${header.class} justify-${header.align}`"
              >
                <div v-if="header.description">
                  <VIcon
                    class="info-icon mr-1 d-flex align-self-center pointer"
                    color="#4D4F67"
                    size="16"
                    v-text="'mdi-alert-circle-outline'"
                  />
                  <div class="description title-description mt-7">
                    {{ header.description }}
                  </div>
                </div>
                <div> {{ header.text }} </div>
                <div
                  v-if="header.sortable"
                  style="position: relative"
                >
                  <VIcon
                    class="sort-icon ml-1"
                    :class="{'rotate180deg': selectedSort[header.sortValue] === 'desc'}"
                    :color="isActiveSort(header.sortValue) ? 'black' : 'grey'"
                    dark
                    size="18"
                    @click.stop="setSort(header.sortValue)"
                    v-text="'mdi-arrow-up'"
                  />
                  <div class="description sort-description mt-6">
                    Сортировать
                  </div>
                </div>
              </div>
            </th>
            <th></th>
          </tr>
          <slot name="filters" />
        </thead>
        <tbody>
          <tr
            v-for="(item, itemIndex) in itemsToEdit"
            :key="itemIndex"
          >
            <td
              v-for="(header, headerIndex) in headers"
              :key="headerIndex"
              :class="`text-${header.align}`"
              :style="`max-width: ${header.width}`"
              @click="header.clickable ? $emit('edit', item) : ''"
            >
              <Component
                :is="getComponentName(header)"
                v-if="header.component"
                v-bind="getComponentProps(item, header)"
                :class="header.class"
                @click="handler($event, item)"
              />
              <div
                v-else
                :class="{
                  'text--secondary': (header.value === 'dateRangeText' && item.isExpired),
                  'bright-green-text': item[header.value] === 'Активирован',
                  'red--text text--darken-1': item[header.value] === 'Деактивирован',
                  'clickable': header.clickable,
                  [header.class]: true,
                }"
              >
                {{ getValue(item, header.value) }}
              </div>
            </td>
          </tr>
          <tr v-if="!(itemsToEdit && itemsToEdit.length)">
            <td
              class="text-center"
              :colspan="headers.length"
            >
              Нет данных
            </td>
          </tr>
        </tbody>
      </template>
    </VSimpleTable>
    <VPagination
      v-if="showPages"
      v-model="currentPage"
      class="py-4"
      :length="totalPage"
      :total-visible="7"
    />
    <VCard
      v-else
      class="bottom-sticky grey lighten-5"
      flat
      height="57"
    >
      <VDivider />
      <div
        v-if="!loading"
        class="d-flex justify-center align-center footer py-2"
      >
        {{ `${slidesRangePerPage} из ${totalCount}` }}
        <VDivider
          class="ml-3"
          inset
          vertical
        />
        <VBtn
          :disabled="currentPage <= 1"
          icon
          small
          @click="currentPage -= 1"
        >
          <VIcon
            size="20"
            v-text="'mdi-chevron-left'"
          />
        </VBtn>
        <VBtn
          :disabled="currentPage >= totalPage"
          icon
          small
          @click="currentPage += 1"
        >
          <VIcon
            size="20"
            v-text="'mdi-chevron-right'"
          />
        </VBtn>
      </div>
    </VCard>
  </div>
</template>

<script>
import { itemsPerPage } from '@/helpers/table';

export default {
  components: {
    TableTitle: () => import('@/components/reusable/TableTitle.vue'),
    Cover: () => import('@/components/reusable/Cover.vue'),
    IconButton: () => import('@/components/reusable/IconButton.vue'),
    ChipGroup: () => import('@/components/reusable/ChipGroup.vue'),
    Rating: () => import('@/components/reusable/Rating.vue'),
    State: () => import('@/components/reusable/State.vue'),
    TableTargets: () => import('@/components/reusable/TableTargets.vue'),
    FullScreenToggle: () => import('@/components/reusable/FullScreenToggle.vue'),
    TableActions: () => import('@/components/reusable/TableActions.vue'),
    TableStateActions: () => import('@/components/reusable/TableStateActions.vue'),
    VHTMLData: () => import('@/components/reusable/VHTMLData.vue'),
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    topMargin: {
      type: Number,
      default: 250,
    },
    showPages: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit', 'update-page', 'update-sort'],
  data() {
    return {
      tableHeight: window.innerHeight - this.topMargin,
      currentPage: 1,
      totalPage: 0,
      totalCount: 0,
      deleteConfirmation: false,
      itemsPerPage,
      loading: false,
      closeOnContentClick: true,
      selectedSort: {},
      itemsToEdit: [],
    };
  },
  mounted() {
    this.currentPage = this.page;
    if (this.items && this.items.length) {
      this.itemsToEdit = [...JSON.parse(JSON.stringify(this.items))];
    }
    this.$nextTick().then(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  computed: {
    slidesRangePerPage() {
      const start = (this.items.length ? 1 : 0) + ((this.currentPage - 1) * this.itemsPerPage);
      const end = this.items.length + ((this.currentPage - 1) * this.itemsPerPage);
      return `${start}-${end}`;
    },
  },
  methods: {
    getComponentName(header) {
      if (header.component) {
        return header.component.key;
      }
      return null;
    },
    getComponentProps(item, header) {
      const props = header.component.props || {};
      props.items = item[header.value] || [];
      props.item = item;
      props.value = item[header.value];
      props.state = item.state;
      return props;
    },
    getValue(item, value) {
      return value.split('.').reduce((o, i) => (o ? o[i] : null), item);
    },
    handler(eventType, event) {
      this.$emit(eventType, event);
    },
    setSort(key) {
      if (!this.selectedSort[key]) {
        this.selectedSort = {};
        this.selectedSort[key] = 'asc';
      } else if (this.selectedSort[key] === 'asc') {
        this.selectedSort = {};
        this.selectedSort[key] = 'desc';
      } else if (this.selectedSort[key] === 'desc') {
        this.selectedSort = {};
      }
    },
    isActiveSort(key) {
      return !!this.selectedSort[key];
    },
    onResize() {
      this.tableHeight = window.innerHeight - this.topMargin;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    page(val) {
      this.currentPage = val;
    },
    currentPage(val) {
      this.$emit('update-page', val);
    },
    items(val) {
      this.itemsToEdit = [...JSON.parse(JSON.stringify(val))];
    },
    selectedSort(val) {
      this.$emit('update-sort', val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

::v-deep {
  .v-icon.v-icon::after {
    background-color: transparent;
  }
  th, td {
    padding-right: 6px !important;
  }
}
.rotate180deg {
  transform: rotate(180deg);
}
.title-description, .sort-description {
  display: none;
}
.sort-description {
  right: 0;
}
.info-icon:hover + .title-description, .sort-icon:hover + .sort-description {
  display: block;
}
.clickable {
  cursor: pointer;
  &:hover {
    color: #E43539;
  }
}
</style>
