<template>
  <VCard id="story-edit">
    <div class="top-sticky white">
      <div class="d-flex justify-space-between align-center pa-2">
        <Breadcrumbs :items="breadcrumbs" />
        <VBtn icon @click="closeStoryDialog">
          <VIcon v-text="'mdi-close'" />
        </VBtn>
      </div>
      <VDivider />
    </div>
    <VForm ref="form" lazy-validation>
      <VRow class="mx-0">
        <VCol class="pt-6 pb-12 pl-6 pr-10 mb-2">
          <WideTextField
            v-model="storyToEdit.title"
            label="Название Story"
          />
          <div class="mt-10 mb-6">
            <div class="section-title">
              Обложка
            </div>
            <div class="caption">
              Обложка будет на главной странице приложения. Вес не более 200 КБ.
            </div>
            <div class="d-flex align-center mt-3">
              <ImageUploadBox
                v-model="storyToEdit.cover"
                :deletable="!!storyToEdit.cover"
                enlargeable
                required
                text="Загрузите обложку"
                @delete="storyToEdit.cover = ''"
                @open-image="$refs.slides.openImage(0)"
              />
              <StoryTextField
                v-model="storyToEdit.coverTitle"
                class="ml-5"
                clearable
                label="Заголовок обложки"
                :rules="[rules.sole]"
              />
            </div>
          </div>
          <SlidesSelection
            ref="slides"
            :story-cover="storyToEdit.cover || ''"
            :story-id="storyToEdit.id || ''"
          />
          <StoryPersonalized
            v-if="storyToEdit.id"
            ref="personalized"
            :story-id="storyToEdit.id"
          />
        </VCol>
        <VDivider vertical />
        <VCol
          class="pa-4 d-flex flex-column"
          cols="3"
        >
          <div>
            <div class="font-weight-bold mb-2">
              Статус
            </div>
            <VMenu
              content-class="elevation-1"
              :disabled="!storyToEdit.id"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <VBtn
                  v-bind="attrs"
                  block
                  class="elevation-0 d-flex justify-space-between pl-1 pr-2"
                  :color="stateParams[storyToEdit.state].color"
                  :style="{'color': stateParams[storyToEdit.state].textColor}"
                  v-on="on"
                >
                  <div class="subtitle-1">
                    <VIcon v-text="'mdi-circle-small'" />
                    {{ stateParams[storyToEdit.state].title }}
                  </div>
                  <VIcon
                    v-if="storyToEdit.id"
                    size="18"
                    v-text="'mdi-chevron-down'"
                  />
                </VBtn>
              </template>
              <VList>
                <VListItemGroup v-model="storyToEdit.state">
                  <VListItem
                    v-for="(state, index) in states"
                    v-show="state !== storyToEdit.state"
                    :key="index"
                    dense
                    :value="state"
                  >
                    <VListItemTitle
                      class="subtitle-1 pl-6"
                      v-text="stateParams[state].title"
                    />
                  </VListItem>
                </VListItemGroup>
              </VList>
            </VMenu>
          </div>
          <VDivider class="mt-3 mb-4" />
          <div>
            <div class="font-weight-bold mb-2">
              Позиция
            </div>
            <VTextField
              v-model.number="storyToEdit.position"
              v-mask="'#######'"
              background-color="#FAFAFB"
              class="position"
              dense
              flat
              hide-details
              placeholder="Укажите позицию"
              solo
            />
          </div>
          <VDivider class="mt-3 mb-4" />
          <div>
            <div
              id="date"
              class="font-weight-bold mb-2"
            >
              Дата кампании
            </div>
            <DateTimePicker
              attach="#date"
              :date-end="storyToEdit.dateEnd"
              :date-start="storyToEdit.dateStart"
              @date-end="storyToEdit.dateEnd = $event"
              @date-start="storyToEdit.dateStart = $event"
            />
          </div>
          <VDivider class="my-3" />
          <ParamSelector
            v-model="storyToEdit.targets"
            attach="targets"
            :items="targets"
            required
            title="Платформы"
          />
          <VDivider class="my-4" />
          <ParamSelector
            v-model="storyToEdit.cities"
            attach="cities"
            height="277"
            :items="cities"
            title="Регионы"
          />
          <VDivider class="my-4" />
          <ParamSelector
            v-model="storyToEdit.hubs"
            attach="hubs"
            height="245"
            :items="hubs"
            searchable
            title="Хабы"
          />
          <VDivider class="my-3" />
          <div>
            <div class="d-flex font-weight-bold justify-space-between">
              <VInput
                v-model="storyToEdit.restaurants"
                hide-details="auto"
              >
                Заведения
              </VInput>
              <DropdownPaginated
                :current-page="restaurants.currentPage"
                :items="restaurants.dataShort || []"
                :loading="restaurantsAreLoading"
                offset-y
                search-placeholder="Поиск заведений"
                :selected="storyToEdit.restaurants"
                title="Все заведения"
                :total-page="restaurants.totalPages"
                @page="getRestaurants($event.search, $event.page)"
                @search="getRestaurants"
                @selected="storyToEdit.restaurants = $event"
              />
            </div>
            <div
              v-if="!storyToEdit.restaurants.length"
              class="subtitle-1 px-2"
            >
              Укажите заведения
            </div>
            <ChipGroup
              v-else
              :items="storyToEdit.restaurants"
              :max="6"
              @remove="removeRestaurant"
            />
          </div>
          <VDivider class="my-4" />
          <ParamSelector
            v-model="storyToEdit.languages"
            attach="languages"
            :items="languages"
            required
            title="Языки"
          />
          <VDivider class="mt-4" />
          <div class="d-flex justify-space-between">
            <p class="font-weight-bold my-auto">
              Автооткрытие
            </p>
            <FullScreenToggle v-model="storyToEdit.isFullScreen" />
          </div>
          <VDivider />
        </VCol>
      </VRow>
    </VForm>
    <div class="bottom-sticky">
      <VDivider />
      <div
        class="d-flex align-center grey lighten-5 px-4 py-3"
        :class="storyToEdit.created ? 'justify-space-between' : 'justify-end'"
      >
        <div
          v-if="storyToEdit.id"
          class="text--secondary"
        >
          <span v-if="storyToEdit.dateModification">
            Изменено: {{ formatDate(storyToEdit.dateModification) }}
          </span>
          <span v-else>
            Создано: {{ formatDate(storyToEdit.created) }}
          </span>
        </div>
        <div class="subtitle-2">
          <VBtn
            v-show="!storyToEdit.id"
            id="save-btn"
            class="subtitle-2 mr-2 br-8 font-weight-bold"
            color="#E43539"
            height="40"
            text
            @click="createStory"
          >
            Сохранить
          </VBtn>
          <VBtn
            class="subtitle-2 white--text elevation-0 br-8 font-weight-bold"
            color="#E43539"
            height="40"
            @click="saveStory"
          >
            {{ storyToEdit.id ? 'Обновить и закрыть' : 'Создать и закрыть' }}
          </VBtn>
        </div>
      </div>
    </div>
  </VCard>
</template>

<script>
import ImageUploadBox from '@/components/reusable/ImageUploadBox.vue';
import StoryTextField from '@/components/reusable/StoryTextField.vue';
import { storyStates } from '@/conditions/states';
import { formatDateWithTime } from '@/helpers/date';
import { fetchHubRestaurants, createStory, updateStory } from '@/api/api';
import DateTimePicker from '@/components/reusable/DateTimePicker.vue';
import SlidesSelection from '@/components/SlidesSelection.vue';
import Breadcrumbs from '@/components/reusable/Breadcrumbs.vue';
import WideTextField from '@/components/reusable/WideTextField.vue';
import ParamSelector from '@/components/reusable/ParamSelector.vue';
import DropdownPaginated from '@/components/reusable/DropdownList.vue';
import ChipGroup from '@/components/reusable/ChipGroup.vue';
import FullScreenToggle from '@/components/reusable/FullScreenToggle.vue';
import StoryPersonalized from '@/components/StoryPersonalized.vue';

export default {
  components: {
    StoryPersonalized,
    FullScreenToggle,
    ChipGroup,
    DropdownPaginated,
    ParamSelector,
    WideTextField,
    Breadcrumbs,
    SlidesSelection,
    DateTimePicker,
    StoryTextField,
    ImageUploadBox,
  },
  props: {
    story: {
      type: Object,
      default: () => ({}),
    },
    cities: {
      type: Array,
      default: () => [],
    },
    hubs: {
      type: Array,
      default: () => [],
    },
    languages: {
      type: Array,
      default: () => [],
    },
    targets: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update', 'close'],
  data() {
    return {
      storyToEdit: {
        title: null,
        coverTitle: null,
        state: 'active',
        position: null,
        cover: null,
        cities: [],
        hubs: [],
        restaurants: [],
        targets: [],
        languages: [],
        isFullScreen: false,
      },
      restaurants: {},
      restaurantsAreLoading: false,
      stateParams: storyStates,
      states: ['active', 'inactive'],
      formatDate: formatDateWithTime,
      rules: {
        sole: (value) => !!value || 'Обязательное поле',
        selection: (value) => value.length > 0 || 'Обязательное поле',
      },
      imageDialog: false,
      imageModel: 0,
      selectedImage: 0,
      key: 0,
    };
  },
  created() {
    this.getRestaurants();
    if (Object.keys(this.story).length) {
      this.storyToEdit = { ...JSON.parse(JSON.stringify(this.story)) };
      this.updateTargets();
    }
  },
  computed: {
    breadcrumbs() {
      return [
        { text: 'Stories', disabled: false, href: '/stories' },
        {
          text: this.story && this.story.id ? `Story ID-${this.storyToEdit.id}` : 'Создать новую Story',
          disabled: true,
          href: '',
        },
      ];
    },
  },
  methods: {
    getRestaurants(search = '', page = 1) {
      const params = { page, limit: 50 };
      if (search && search.length > 0) {
        params.title = search;
      }
      this.restaurantsAreLoading = true;
      fetchHubRestaurants(params).then((response) => {
        this.restaurants = response;
        this.restaurants.currentPage = response.data ? response.currentPage : 0;
      }).finally(() => {
        this.restaurantsAreLoading = false;
      });
    },
    removeRestaurant(index) {
      this.storyToEdit.restaurants.splice(index, 1);
    },
    updateTargets() {
      if (this.storyToEdit.targets.length) {
        this.storyToEdit.targets = this.storyToEdit.targets
          .map((target) => this.targets.find((item) => item.value === target));
      }
    },
    createStory() {
      if (this.$refs.form.validate()) {
        createStory(this.storyToEdit).then((response) => {
          this.storyToEdit = response;
          this.updateTargets();
          this.$emit('update');
        });
      }
    },
    saveStory() {
      if (this.$refs.form.validate()) {
        if (this.storyToEdit.id) {
          updateStory(this.storyToEdit).then(() => {
            this.$refs.slides.saveSlidesList();
            this.$emit('update');
          });
          this.$refs.personalized.updatePersonalized();
        } else {
          this.createStory();
        }
        this.closeStoryDialog();
      }
    },
    closeStoryDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

::v-deep {
  .position {
    input {
      font-size: 16px;
      &::placeholder {
        color: #6D7885;
      }
    }
  }
}
</style>
