<template>
  <div
    id="page-header"
    class="d-flex justify-space-between mb-4"
  >
    <div class="page-title">
      {{ pageTitle }}
    </div>
    <div class="d-flex">
      <VBtn
        v-if="createBtnTitle.length"
        class="br-8 mr-3 white--text subtitle-2 font-weight-bold"
        color="#E43539"
        elevation="0"
        height="40px"
        @click="$emit('click:create')"
      >
        {{ createBtnTitle }}
      </VBtn>
      <VMenu
        v-if="searchable"
        v-model="showMenu"
        :disabled="hideMenu"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <VTextField
            id="search-field"
            v-model="searchInput"
            v-bind="attrs"
            background-color="#FAFAFB"
            class="search-field font-weight-bold"
            clearable
            color="#E6E6E9"
            dense
            flat
            hide-details
            hide-selected
            :placeholder="searchPlaceholder"
            prepend-inner-icon="mdi-magnify"
            solo
            v-on="on"
            @input="showMenu = true"
          />
        </template>
        <VList
          class="py-2 overflow-auto"
          dense
          max-height="300"
          width="359"
        >
          <VListItemGroup
            v-show="searchItems.length"
            v-model="search"
          >
            <VListItem
              v-for="(item, index) in searchItems"
              :key="index"
              :value="item"
            >
              {{ item.title }}
            </VListItem>
          </VListItemGroup>
          <VListItem
            v-show="!searchItems.length"
            class="pl-10"
          >
            По запросу ничего не найдено
          </VListItem>
        </VList>
      </VMenu>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageTitle: {
      type: String,
      default: 'Заголовок страницы',
    },
    createBtnTitle: {
      type: String,
      default: '',
    },
    searchPlaceholder: {
      type: String,
      default: 'Поиск по названию и ID',
    },
    searchItems: {
      type: Array,
      default: () => [],
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click:create', 'search-input', 'search'],
  data() {
    return {
      search: '',
      searchInput: null,
      showMenu: false,
    };
  },
  watch: {
    searchInput() {
      setTimeout(() => {
        this.$emit('search-input', this.searchInput);
      }, 500);
    },
    search(val) {
      this.searchInput = val.title || '';
      this.$emit('search', val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

::v-deep {
  .v-list-item--link {
    margin-left: 8%;
    margin-right: 10px;
  }

  .v-list-item--link:before {
    background-color: red;
    border-radius: 4px;
  }
}
</style>
