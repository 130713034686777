<template>
  <div>
    <div class="d-flex justify-space-between">
      <div
        :id="attach"
        class="font-weight-bold"
      >
        {{ title }}
      </div>
      <DropdownTable
        :attach="attach ? `#${attach}` : null"
        :height="height"
        is-icon
        :item-key="itemKey"
        :items="items"
        :searchable="searchable"
        :selected="selectedItems"
        :title="`Все ${title.toLowerCase()}`"
        @selected="selectedItems = $event"
      />
    </div>
    <VInput
      v-if="!selectedItems.length"
      v-model="selectedItems"
      class="input-slot-subtitle-1 px-2"
      hide-details="auto"
      :rules="required ? rules : []"
    >
      {{ `Укажите ${title.toLowerCase()}` }}
    </VInput>
    <ChipGroup
      v-else
      :items="selectedItems"
      :max="max"
      @remove="removeItem"
    />
  </div>
</template>

<script>
import DropdownTable from '@/components/reusable/DropdownTable.vue';
import ChipGroup from '@/components/reusable/ChipGroup.vue';

export default {
  components: {
    DropdownTable,
    ChipGroup,
  },
  props: {
    title: {
      type: String,
      default: 'Регионы',
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    value: { // selectedItems
      type: Array,
      default: () => ([]),
    },
    required: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: 7,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '',
    },
    itemKey: {
      type: String,
      default: 'title',
    },
  },
  emits: ['input'],
  data() {
    return {
      selectedItems: [],
      rules: [(value) => value.length > 0 || 'Обязательное поле'],
    };
  },
  mounted() {
    if (this.value.length) {
      this.selectedItems = this.value;
    }
  },
  methods: {
    removeItem(index) {
      if (index >= 0) {
        this.selectedItems.splice(index, 1);
      }
    },
  },
  watch: {
    value(items) {
      this.selectedItems = items;
    },
    selectedItems(items) {
      this.$emit('input', items);
    },
  },
};
</script>
