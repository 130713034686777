<template>
  <VMenu
    v-model="menu"
    :attach="attach || false"
    :close-on-content-click="false"
    content-class="br-4 font-weight-medium"
    :disabled="disabled"
    min-width="280"
    nudge-bottom="3"
    :nudge-left="nudgeLeft"
    offset-overflow
    :offset-y="offsetY"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-if="isCustom"
        v-bind="attrs"
        v-on="on"
      >
        <slot name="activator" />
      </div>
      <VBtn
        v-else
        v-bind="attrs"
        icon
        small
        v-on="on"
      >
        <VIcon :color="menu ? '#E43539' : 'blue-grey darken-3'">
          mdi-plus
        </VIcon>
      </VBtn>
    </template>
    <VCard :width="width">
      <div>
        <slot name="filters" />
      </div>
      <VTextField
        v-model="search"
        background-color="white"
        class="subtitle-2 font-weight-bold"
        clearable
        color="#A4A2B7"
        dense
        flat
        hide-details
        :placeholder="searchPlaceholder"
        prepend-inner-icon="mdi-magnify"
        solo
      />
      <VDivider />
      <VCard flat :height="height + 44">
        <span v-if="!loading">
          <span v-if="items.length > 0">
            <span v-show="items.length > 1">
              <VListItem class="fixed-height" dense>
                <VIcon
                  class="pl-0 pr-2"
                  :color="isAllItemsOnPageSelected ? '#E43539' : '#E6E6E9'"
                  @click="toggle"
                  v-text="icon"
                />
                <span>{{ `${title} на странице` }}</span>
              </VListItem>
              <VDivider />
            </span>
            <VList
              class="overflow-y-auto"
              dense
              :height="height"
            >
              <VListItem
                v-for="(item, index) in items"
                :key="index"
              >
                <VCheckbox
                  :key="item.id"
                  v-model="selectedItems"
                  class="pa-0 my-auto light-grey-checkbox white--text"
                  color="#E43539"
                  hide-details
                  multiple
                  :value="item"
                  @change="$emit('selected', $event)"
                />
                {{ item.title }}
              </VListItem>
            </VList>
          </span>
          <p v-else class="text-center pt-3 fixed-height"> Нет данных </p>
        </span>
        <RotatingIcon
          v-else
          class="ml-3 fixed-height"
          text="Загружается..."
        />
      </VCard>
      <VCard flat>
        <VDivider />
        <div class="d-flex justify-center align-center py-1">
          {{ `страница ${currentPageToEdit} из ${totalPage}` }}
          <VDivider class="ml-3 my-1" vertical />
          <VBtn
            :disabled="currentPageToEdit <= 1"
            icon
            small
            @click="currentPageToEdit -= 1"
          >
            <VIcon size="20">
              mdi-chevron-left
            </VIcon>
          </VBtn>
          <VBtn
            :disabled="currentPageToEdit >= totalPage"
            icon
            small
            @click="currentPageToEdit += 1"
          >
            <VIcon size="20">
              mdi-chevron-right
            </VIcon>
          </VBtn>
        </div>
      </VCard>
    </VCard>
  </VMenu>
</template>

<script>
import RotatingIcon from '@/components/reusable/RotatingIcon.vue';

export default {
  components: { RotatingIcon },
  props: {
    items: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Все',
    },
    searchPlaceholder: {
      type: String,
      default: 'Поиск',
    },
    width: {
      type: String,
      default: '288',
    },
    height: {
      type: Number,
      default: 225,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    totalPage: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: String,
    },
    isCustom: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    offsetY: {
      type: Boolean,
      default: false,
    },
    nudgeLeft: {
      type: String,
      default: '250',
    },
  },
  emits: ['selected', 'search', 'page', 'activated'],
  data() {
    return {
      menu: false,
      selectedItems: [],
      search: '',
      currentPageToEdit: 0,
      timeout: null,
      isActivated: false,
    };
  },
  mounted() {
    this.selectedItems = this.selected;
    this.currentPageToEdit = this.currentPage;
  },
  computed: {
    isAllItemsOnPageSelected() {
      let overlapsCount = 0;
      this.items.forEach((item) => {
        if (this.selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
          overlapsCount += 1;
        }
      });
      return overlapsCount === this.items.length;
    },
    icon() {
      if (this.isAllItemsOnPageSelected) { return 'mdi-checkbox-marked'; }
      return 'mdi-checkbox-blank-outline';
    },
  },
  methods: {
    toggle() {
      if (this.isAllItemsOnPageSelected) {
        this.items.forEach((item) => {
          this.selectedItems = this.selectedItems
            .filter((selectedItem) => selectedItem.id !== item.id);
        });
      } else {
        this.items.forEach((item) => {
          if (!this.selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
            this.selectedItems.push(item);
          }
        });
      }
      this.$emit('selected', this.selectedItems);
    },
  },
  watch: {
    selected(items) {
      this.selectedItems = items;
    },
    search(val) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.$emit('search', val);
      }, 500);
    },
    currentPageToEdit(page) {
      if (page !== this.currentPage) {
        this.$emit('page', { search: this.search, page });
      }
    },
    currentPage(val) {
      this.currentPageToEdit = val;
    },
    menu(isActive) {
      if (isActive && !this.isActivated) {
        this.$emit('activated');
        this.isActivated = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

::v-deep {
  .v-input--selection-controls__ripple {
    display: none;
  }
  .v-icon::after {
    background-color: transparent;
  }
}
.fixed-height {
  height: 44px;
}
</style>
