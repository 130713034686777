<template>
  <VCard flat>
    <VRow class="ma-0">
      <VCol class="d-flex align-center px-1" cols="1">
        <VImg
          contain
          height="10"
          :src="require('@/assets/icons/dots.svg')"
          width="18"
        />
        <div> {{ slideToEdit.position }} </div>
      </VCol>
      <VCol
        class="py-0"
        cols="2"
        md="3"
        sm="3"
      >
        <ImageUploadBox
          v-model="slideToEdit.image"
          deletable
          enlargeable
          height="194"
          required
          width="110"
          @delete="$emit('delete-slide')"
          @open-image="$emit('open-image')"
        />
      </VCol>
      <VCol
        class="pt-6 pl-0"
        cols="5"
        md="5"
        sm="4"
      >
        <StoryTextField
          v-model="slideToEdit.buttonName"
          clearable
          label="Текст кнопки"
        />
        <StoryTextField
          v-model="slideToEdit.buttonLink"
          clearable
          label="Ссылка для кнопки"
        />
        <VMenu content-class="elevation-1" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <VBtn
              v-bind="attrs"
              class="dropdown greyish elevation-0 mb-4 subtitle-2 px-3"
              v-on="on"
            >
              <span> {{ buttonTypeTitles[slideToEdit.buttonType] }} </span>
              <VIcon size="20" v-text="'mdi-chevron-down'" />
            </VBtn>
          </template>
          <VList>
            <VListItemGroup v-model="slideToEdit.buttonType">
              <VListItem
                v-for="(type, index) in buttonTypes"
                v-show="slideToEdit.buttonType !== type"
                :key="index"
                dense
                :value="type"
              >
                <VListItemTitle class="subtitle-2 ml-1">
                  {{ buttonTypeTitles[type] }}
                </VListItemTitle>
              </VListItem>
            </VListItemGroup>
          </VList>
        </VMenu>
      </VCol>
      <VCol
        class="pt-6 pr-10"
        cols="3"
        md="3"
        sm="4"
      >
        <div class="d-flex align-center">
          <VMenu
            v-model="buttonColorPicker"
            :close-on-content-click="false"
            content-class="br-12"
            nudge-right="165"
            nudge-top="100"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                v-bind="attrs"
                class="elevation-0 mb-3 mr-2 grey-br"
                :color="slideToEdit.buttonColor"
                fab
                height="20"
                width="20"
                v-on="on"
              />
            </template>
            <ColorPicker
              v-if="buttonColorPicker"
              v-model="slideToEdit.buttonColor"
              :saved-colors.sync="savedColors.button"
              @close="buttonColorPicker = false"
              @deleteColor="deleteColor($event, 'button')"
              @saveColor="saveColor($event, 'button')"
            />
          </VMenu>
          <StoryTextField
            v-model="slideToEdit.buttonColor"
            label="Цвет кнопки"
            placeholder="#E43539"
            :rules="[(v) => v.startsWith('#') && v.length === 7]"
          />
        </div>
        <div class="d-flex align-center">
          <VMenu
            v-model="textColorPicker"
            :close-on-content-click="false"
            content-class="br-12"
            nudge-right="165"
            nudge-top="100"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                v-bind="attrs"
                class="elevation-0 mb-3 mr-2 grey-br"
                :color="slideToEdit.textColor"
                fab
                height="20"
                width="20"
                v-on="on"
              />
            </template>
            <ColorPicker
              v-if="textColorPicker"
              v-model="slideToEdit.textColor"
              :saved-colors="savedColors.text"
              @close="textColorPicker = false"
              @deleteColor="deleteColor($event, 'text')"
              @saveColor="saveColor($event, 'text')"
            />
          </VMenu>
          <StoryTextField
            v-model="slideToEdit.textColor"
            label="Цвет текста"
            placeholder="#FFFFFF"
            :rules="[(v) => v.startsWith('#') && v.length === 7]"
          />
        </div>
      </VCol>
    </VRow>
  </VCard>
</template>

<script>
import ImageUploadBox from '@/components/reusable/ImageUploadBox.vue';
import StoryTextField from '@/components/reusable/StoryTextField.vue';
import ColorPicker from '@/components/reusable/ColorPicker.vue';

export default {
  components: { ColorPicker, ImageUploadBox, StoryTextField },
  props: {
    storyId: {
      type: [Number, String],
      required: true,
    },
    slide: {
      type: Object,
      default: () => ({}),
    },
    position: {
      type: [String, Number],
      default: null,
    },
  },
  emits: ['delete-slide', 'open-image'],
  data() {
    return {
      slideToEdit: {},
      buttonTypes: ['inside_link', 'outside_link'],
      buttonTypeTitles: { inside_link: 'Внутренняя ссылка', outside_link: 'Внешняя ссылка' },
      savedColors: {
        button: [],
        text: [],
      },
      buttonColorPicker: false,
      textColorPicker: false,
    };
  },
  mounted() {
    this.setSlideToEdit(this.slide);
  },
  methods: {
    setSlideToEdit(slide) {
      if (Object.keys(slide).length) {
        this.slideToEdit = slide;
        if (this.position) {
          this.slideToEdit.position = this.position;
        }
        if (!this.buttonTypes.includes(this.slideToEdit.buttonType)) {
          this.slideToEdit.buttonType = 'inside_link';
        }
      }
    },
    update(color, key) {
      this.slideToEdit[key] = color;
    },
    saveColor(color, key) {
      this.savedColors[key].push(color);
    },
    deleteColor(index, key) {
      this.savedColors[key].splice(index, 1);
    },
  },
  watch: {
    slide(val) {
      this.setSlideToEdit(val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
