<template>
  <VContainer class="pt-2 px-0 pb-0" fluid>
    <VTextField
      id="story-text-field"
      v-model="valueToEdit"
      class="subtitle-1"
      clear-icon="mdi-close"
      :clearable="clearable"
      color="#4D4F67"
      dense
      :disabled="disabled"
      :label="label"
      :placeholder="placeholder"
      :readonly="readonly"
      :rules="rules"
      :value="valueToEdit"
      @input="input"
    />
  </VContainer>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input'],
  data() {
    return {
      valueToEdit: '',
    };
  },
  mounted() {
    if (this.value) {
      this.valueToEdit = this.value;
    }
  },
  methods: {
    input() {
      this.$emit('input', this.valueToEdit);
    },
  },
  watch: {
    value(val) {
      this.valueToEdit = val;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-text-field {
    max-width: 517px;
    input {
      margin-bottom: 6px;
    }
  }
  .mdi-close {
    font-size: 18px;
    color: #A4A2B7;
  }
  .v-input__prepend-inner {
    align-self: flex-end;
    margin-bottom: 11px;
    padding-right: 0 !important;
  }
}
</style>
