<template>
  <div>
    <div class="pt-5 px-8">
      <PageHeader
        ref="pageHeader"
        create-btn-title="Создать новую Story"
        page-title="Stories"
        :search-items="storiesForSearch"
        @click:create="edit({})"
        @search="search = $event"
        @search-input="searchInput = $event"
      />
      <VDialog
        v-model="storyDialog"
        content-class="br-12"
        max-width="1248"
      >
        <Story
          :key="key"
          :cities="cities"
          :hubs="hubs"
          :languages="languagesList"
          :story="storyToEdit"
          :targets="targets"
          @close="storyDialog = false"
          @update="getStories(currentPage)"
        />
      </VDialog>
      <VDivider />
      <div class="d-flex my-4">
        <Dropdown
          height="420"
          :items="cities"
          searchable
          :selected="selectedCities"
          title="Все регионы"
          @selected="selectedCities = $event"
        />
        <Dropdown
          height="420"
          :items="hubs"
          searchable
          :selected="selectedHubs"
          title="Все хабы"
          @selected="selectedHubs = $event"
        />
        <Dropdown
          :items="states"
          :selected="selectedStates"
          title="Все статусы"
          @selected="selectedStates = $event"
        />
        <VBtn
          class="dropdown subtitle-2 elevation-0"
          :class="showExpired ? 'reddish' : 'greyish'"
          @click="showExpired = !showExpired"
        >
          Показать истекшие
        </VBtn>
      </div>
    </div>
    <UniversalTable
      ref="table"
      :headers="storiesHeaders(cities.length || 0, hubs.length || 0)"
      :items="stories"
      :page="currentPage"
      @add-full-screen="updateStoryIsFullScreen($event, true)"
      @deactivate="deactivateStory"
      @delete="deleteStory"
      @edit="edit"
      @remove-full-screen="updateStoryIsFullScreen($event, false)"
      @update-page="currentPage = $event"
      @update-sort="sort"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  fetchStories,
  fetchStoryById,
  deleteStory,
  updateStory,
  getLanguagesList,
  fetchHubs,
  getTargetList,
} from '@/api/api';
import { storiesHeaders } from '@/conditions/tablesHeaders';
import { itemsPerPage } from '@/helpers/table';
import PageHeader from '@/components/reusable/PageHeader.vue';
import Story from '@/components/Story.vue';
import Dropdown from '@/components/reusable/DropdownTable.vue';
import UniversalTable from '@/components/reusable/UniversalTable.vue';

const defaultStates = [{ title: 'Активно', value: 'active' }, { title: 'Отключено', value: 'inactive' }];

export default {
  components: {
    PageHeader, Story, Dropdown, UniversalTable,
  },
  data() {
    return {
      stories: [],
      defaultStories: [],
      storiesHeaders,
      states: defaultStates,
      selectedStates: defaultStates,
      selectedCities: [],
      showExpired: false,
      currentPage: 1,
      itemsPerPage,
      storiesForSearch: [],
      search: null,
      searchInput: null,
      storyDialog: false,
      storyToEdit: {},
      key: 0,
      timeout: null,
      languagesList: [],
      hubs: [],
      selectedHubs: [],
      targets: [],
    };
  },
  mounted() {
    this.getCities().then(() => {
      this.selectedCities = this.cities;
    })
      .catch(() => this.getStories());
    this.fetchLanguagesList();
    this.fetchTargetList();
    fetchHubs({ limit: 100 }).then((hubs) => {
      this.hubs = hubs.data;
      this.selectedHubs = hubs.data;
    });
  },
  computed: {
    ...mapGetters(['cities']),
    params() {
      const params = {
        expired: this.showExpired,
        limit: this.itemsPerPage,
      };
      if (this.selectedStates.length === 1) {
        params.state = this.selectedStates[0].value;
      }
      return params;
    },
  },
  methods: {
    ...mapActions(['getCities']),
    edit(story) {
      this.storyToEdit = story;
      this.key += 1;
      this.storyDialog = true;
    },
    urlListParams(total = [], selected = [], listTitle = '', listItemKey = 'id') {
      let list = [];
      if (selected.length === total.length) {
        return list;
      }
      selected.forEach((item, index) => {
        list += `${index === 0 ? '' : '&'}${listTitle}=${item[listItemKey]}`;
      });
      return list;
    },
    getSelectedCitiesAndHubs() {
      const cities = this.urlListParams(this.cities, this.selectedCities, 'cities', 'pk');
      const hubs = this.urlListParams(this.hubs, this.selectedHubs, 'hubs', 'id');
      return `?${cities}${cities.length ? '&' : ''}${hubs}`;
    },
    getStories(page = 1) {
      this.$refs.table.loading = true;
      this.$refs.pageHeader.searchInput = null;
      const params = this.params;
      params.page = page;
      fetchStories(params, this.getSelectedCitiesAndHubs()).then((response) => {
        this.stories = response.stories;
        this.defaultStories = [...JSON.parse(JSON.stringify(response.stories))];
        this.currentPage = response.current_page;
        this.$refs.table.totalPage = response.total_page;
        this.$refs.table.totalCount = response.total_count;
      }).finally(() => {
        this.$refs.table.loading = false;
      });
    },
    getStoriesForSearch(search) {
      const params = { ...JSON.parse(JSON.stringify(this.params)) };
      params.search = search;
      params.page = 1;
      fetchStories(params, this.getSelectedCitiesAndHubs()).then((response) => {
        this.storiesForSearch = response.stories;
      });
    },
    deleteStory(story) {
      deleteStory(story.id).then(() => {
        this.getStories(this.currentPage);
      });
    },
    deactivateStory(story) {
      // eslint-disable-next-line no-param-reassign
      story.state = 'inactive';
      updateStory(story).then(() => {
        this.getStories(this.currentPage);
      });
    },
    updateStoryIsFullScreen(story, isFullScreen = false) {
      // eslint-disable-next-line no-param-reassign
      story.isFullScreen = isFullScreen;
      updateStory(story).then(() => {
        this.getStories(this.currentPage);
      });
    },
    sort(selectedSort) {
      const key = Object.keys(selectedSort)[0];
      if (key && key.length) {
        const valueType = typeof this.stories[0][key];
        const sortingOptions = {
          string: {
            asc: (a, b) => a[key].localeCompare(b[key]),
            desc: (a, b) => b[key].localeCompare(a[key]),
          },
          number: {
            asc: (a, b) => {
              if (a[key] < b[key]) {
                return -1;
              }
              if (a[key] > b[key]) {
                return 1;
              }
              return 0;
            },
            desc: (a, b) => {
              if (b[key] < a[key]) {
                return -1;
              }
              if (b[key] > a[key]) {
                return 1;
              }
              return 0;
            },
          },
        };
        this.stories.sort(sortingOptions[valueType][selectedSort[key]]);
      } else {
        this.stories = [...JSON.parse(JSON.stringify(this.defaultStories))];
      }
    },
    fetchLanguagesList() {
      getLanguagesList().then((result) => {
        this.languagesList = result;
      });
    },
    fetchTargetList() {
      getTargetList().then((result) => {
        this.targets = result.map(item => ({ title: item.title, value: item.code }));
      });
    },
  },
  watch: {
    selectedCities() {
      this.getStories();
    },
    selectedHubs() {
      this.getStories();
    },
    params: {
      deep: true,
      handler() {
        this.getStories();
      },
    },
    searchInput(val) {
      if (val) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getStoriesForSearch(val);
        }, 500);
      } else {
        this.search = null;
        this.storiesForSearch = [];
      }
    },
    search(item) {
      if (item && item.id) {
        fetchStoryById(item.id).then((response) => {
          this.stories = [response];
          this.$refs.table.totalPage = 1;
          this.$refs.table.totalCount = 1;
        });
      } else {
        this.getStories();
      }
    },
    currentPage(val) {
      this.getStories(val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

</style>
